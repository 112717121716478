import './schedule.css';

function Schedule() {
    return (
        <div className="schedule-wrapper flex">
            <div className="container">
                <h2 className="title">LAND SALE MINT SCHEDULE</h2>
                <div className="over">
                    <div className="schedule-content">
                        <ul className="flex list-none">
                            <li>
                                <div className="slots-wrap flex">
                                    <h2>TIME SLOT</h2>
                                    <p>All Times in UTC</p>
                                    <small>UTC | PM</small>
                                    <span>4:00</span>
                                    <span>5:00</span>
                                    <span>6:00</span>
                                    <span>7:00</span>
                                    <span>8:00</span>
                                    <span>9:00</span>
                                </div>
                            </li>
                            <li>
                                <div className="day-wrap flex">
                                    <h2>DAY 1</h2>
                                    <small>7th July | Thursday</small>
                                    <section>
                                        <h3>PRE MINT</h3>
                                        <p>Minting is enabled for all users who have all 7 unique Cardano Summit NFTs</p>
                                    </section>
                                    <span className="break">Break for Twitter Space AMA</span>
                                    <section>
                                        <h3>MINT 1</h3>
                                        <p>Minting is enabled for all users who have 1 or more Cardano Summit NFTs</p>
                                    </section>
                                </div>
                            </li>
                            <li>
                                <div className="day-wrap">
                                    <h2>DAY 2</h2>
                                    <small>8th July | Friday</small>
                                    <section>
                                        <h3>MINT 2</h3>
                                        <p>Minting is enabled for whitelisted <br/>users</p>
                                    </section>
                                    <span className="break">Break for Twitter Space AMA</span>
                                    <section>
                                        <h3>MINT 3</h3>
                                        <p>Minting is enabled for the general public (7pm onwards)</p>
                                    </section>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Schedule;