import './Home.css';
import Header from "./header/Header";
import Banner from "./banner/Banner";
import Schedule from "./schedule/Schedule";
import Footer from "./footer/Footer";

function Home() {
    return (
        <div>
            <Header/>
            <Banner/>
            <Schedule/>
            <Footer/>
        </div>
    );
}

export default Home;