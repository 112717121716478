import axios  from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    responseType: "json",
    headers: {
        "Content-type": "application/json",
        locale: "en",
        "Authorization": process.env.REACT_APP_AUTHORIZATION_HEADER
    },
});

const apiManager = {
    request: async (
        url,
        body,
        method,
        baseURL = ""
    ) => {
        try {
            return axiosInstance({
                method: method,
                url: baseURL + url,
                data: body,
            });
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(e.message);
            } else {
                throw new Error("An Error Occurred");
            }
        }
    },
};
export default apiManager;