export const VALID_ADDRESS_STRING = ["addr1", "DdzFF", "Ae2"];
export const ERROR_MESSAGES = {
    INVALID_ADDRESS: "Please ensure you’ve entered a valid supported wallet address.",
    EMPTY_FIELD: "Please enter a valid wallet address."
}

export const STATUS_MESSAGES = {
    NOT_WHITELISTED: {
        title: "You’re not on the whitelist!",
        message: "Sorry - Unfortunately your wallet address is not in our whitelist database, but you may still be able to partake in our public sale (starting on 8th July at 7pm UTC). Please ensure that you use your Nami wallet when taking part in the Cardano Island land sale minting process.",
    },
    WHITELISTED_ONLY: {
        title: "You’re on the whitelist!",
        message: "Congratulations! You are eligible to join the Mint 2 stage (starting on 8th July at 4pm UTC), before the general public are given access to the minting process later on.",
    },
    LESS_THEN_SEVEN_SUMMIT_NFT_HOLDER:{
        title: "You hold 1-6 unique Summit NFTs!",
        message: "Congratulations! You are eligible to join the Mint 1 stage (starting on 7th July at 7pm UTC), as well as all of the later minting stages.",
    },
    SEVEN_SUMMIT_NFT_HOLDER: {
        title: "You hold all 7 unique Summit NFTs!",
        message: "Congratulations! You are eligible for the pre-mint (starting on 7th July at 4pm UTC), as well as all of the other minting stages!"
    },
}