import './footer.css';
import Facebook from "../../assets/images/Facebook.svg"
import Twitter from "../../assets/images/twitter.svg"
import Instagram from "../../assets/images/Instagram.svg"
import Discord from "../../assets/images/discord_icon.svg"
import Raddit from "../../assets/images/Reddit.svg"
import Youtube from "../../assets/images/Youtube.svg"
import Medium from "../../assets/images/Medium.svg"
import Telegram from "../../assets/images/Telegram.svg"
import footerLogo from "../../assets/images/footer-logo.svg"

function Footer() {
    return (
        <footer className="footer flex">
            <div className="container">
                <a href="/"><img src={footerLogo} alt="footerLogo"/></a>
                <p>Virtua is a registered trademark of Virtua Limited. All logos are registered trademarks of their respective owners. All content of this document, unless otherwise credited, are copyright @ 2022 Virtua Limited.</p>
            </div>
            <div className="copyright flex">
                <div><span className="mr-auto">All Rights Reserved. Virtua 2022</span></div>
                <div>
                    <ul className="list-none flex social">
                        <li><a href="http://www.facebook.com/virtuametaverse" target="_blank"><img src={Facebook} alt="Facebook"/></a></li>
                        <li><a href="https://twitter.com/Virtuametaverse" target="_blank"><img src={Twitter} alt="Twitter"/></a></li>
                        <li><a href="https://www.instagram.com/Virtuametaverse/" target="_blank"><img src={Instagram} alt="Instagram"/></a></li>
                        <li><a href="https://discord.com/invite/virtua" target="_blank"><img src={Discord} alt="Discord"/></a></li>
                        <li><a href="https://www.reddit.com/user/terravirtua" target="_blank"><img src={Raddit} alt="Raddit"/></a></li>
                        <li><a href="https://www.youtube.com/VirtuaMetaverse" target="_blank"><img src={Youtube} alt="Youtube"/></a></li>
                        <li><a href="https://medium.com/Virtua" target="_blank"><img src={Medium} alt="Medium"/></a></li>
                        <li><a href="https://t.me/Virtuametaverse" target="_blank"><img src={Telegram} alt="Telegram"/></a></li>
                    </ul>
                </div>
                <div>
                    <ul className="page-links list-none ml-auto flex">
                        <li><a href="https://virtua.com/user-guidelines" target="_blank">User Guidelines</a></li>
                        <li><a href="https://virtua.com/privacy-policy" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://virtua.com/terms-service" target="_blank">Terms Of Service</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;