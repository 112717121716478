import "./banner.css";
import {useState} from "react";
import {statusService} from "../../services/statusService";
import {ERROR_MESSAGES, STATUS_MESSAGES, VALID_ADDRESS_STRING} from "../../constants/globalConstants";

const defaultStatusMessage = {
    title: "",
    message: "",
}
function Banner() {
    const [walletAddress, setWalletAddress] = useState("");
    const [statusMessage, setStatusMessage] = useState(defaultStatusMessage);
    const [validationError, setValidationError] = useState("");

    const checkWalletStatus = async () => {
        if (walletAddress && !validationError) {
            const data = await statusService(walletAddress);
            if (data.count === 7) {
                setStatusMessage(STATUS_MESSAGES.SEVEN_SUMMIT_NFT_HOLDER);
                return;
            }
            if (data.count < 7 && data.count >= 1) {
                setStatusMessage(STATUS_MESSAGES.LESS_THEN_SEVEN_SUMMIT_NFT_HOLDER);
                return;
            }
            if (data.count === 0 && data.whitelisted) {
                setStatusMessage(STATUS_MESSAGES.WHITELISTED_ONLY)
                return
            }
            setStatusMessage(STATUS_MESSAGES.NOT_WHITELISTED);
            return;
        }
        setValidationError(ERROR_MESSAGES.INVALID_ADDRESS);
    }

    const validateAddress = (input) => {
        setStatusMessage(defaultStatusMessage);
        if (input) {
            const isNotValid = VALID_ADDRESS_STRING.every( (str) => {
                const checkAgainst = input.length < 5 ? str.substring(0, input.length) : str
                if (!input.startsWith(checkAgainst)) {
                    setValidationError(ERROR_MESSAGES.INVALID_ADDRESS)
                    return true;
                }
                return false;
            })
            !isNotValid && setValidationError("");
        }
        setWalletAddress(input);
    }
    return (
        <>
            <div className="banner-wrapper flex">
                <div className="container">
                    <div className="banner-content">
                        <h1>NAMI WALLET ADDRESS</h1>
                        <p>Verify your Nami wallet address to confirm whitelist eligibility, <br/>and see which mint stages you can access.</p>
                        <div className="banner-nft-form">
                            <input onInput={(e) => validateAddress(e.target.value)} placeholder="Enter Wallet Address" type="text" value={walletAddress}/>
                            {validationError && <span className="error-msg">{validationError}</span>}
                            <button className="form-btn" onClick={checkWalletStatus}>Verify Now</button>
                            { statusMessage.title && <h3> {statusMessage.title} </h3>}
                            { statusMessage.message && <span> {statusMessage.message} </span>}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Banner;